<template>
  <div :class="compact ? 'ProductCarousel--compact' : ''">
    <Carousel
      :scroll-per-page="['mobileS', 'mobileM', 'mobileL'].indexOf($mq) > -1 ? false : true"
      :per-page-custom="[
        [320, 2],
        [425, 2.35],
        [768, 4],
        [1440, 6],
      ]"
      :per-page="4"
      :navigation-enabled="true"
      pagination-active-color="#869791"
      pagination-color="#D6D6D6"
    >
      <Slide v-for="product in products" :key="product.objectID">
        <div class="CardWrapper">
          <ProductCard
            class="Grid__item"
            :enable-amount="settings.xalmode"
            :product="product"
            navigation-next-label="<i class='fal fa-chevron-right'></i>"
            :pagination-position="PaginationPosition.BOTTOM"
            :button-location="buttonLocation"
            @view="clickAction(product.objectID)"
            @addToCart="() => purchaseAction(product.objectID, 'Product Added to Cart')"
          />
        </div>
      </Slide>

      <Slide v-if="lastSlideTitle">
        <div class="CardWrapper">
          <div class="LastSlide">
            <a :href="lastSlideLink" v-html="lastSlideTitle"></a>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { ProductCard } from '@components/Cards';
import { Carousel, Slide } from 'vue-carousel';
import settings from '@settings';

export const PaginationPosition = {
  BOTTOM: 'bottom',
  BOTTOM_OVERLAY: 'overlay',
  TOP: 'top',
  TOP_OVERLAY: 'overlay',
};

export default {
  name: 'ProductsCarousel',
  components: {
    ProductCard,
    Carousel,
    Slide,
  },

  props: {
    products: {
      required: true,
      type: Array,
    },
    purchaseAction: {
      type: Function,
      default() {},
    },
    clickAction: {
      type: Function,
      default() {},
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastSlideTitle: {
      type: String,
      required: false,
      default: '',
    },
    lastSlideLink: {
      type: String,
      required: false,
      default: '',
    },
    buttonLocation: {
      type: String,
      required: false,
      default: '',
    },
  },
  created() {
    this.settings = settings;
    this.PaginationPosition = PaginationPosition;
  },
};
</script>

<style lang="scss">
.VueCarousel-inner {
  margin-right: -20px;
}

.VueCarousel-navigation-button {
  @media only screen and (max-width: $tablet - 1) {
    display: none;
  }

  font-family: 'Font Awesome 5 Pro' !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-size: 0;

  padding: 30px !important;

  &::before {
    font-size: 34px;
  }
}

.VueCarousel-navigation-prev {
  transform: translateY(calc(-50% - 35px)) translateX(-100%) !important;

  &::before {
    content: '\f053';
  }
}

.VueCarousel-navigation-next {
  transform: translateY(calc(-50% - 35px)) translateX(100%) !important;

  &::before {
    content: '\f054';
  }
}

.VueCarousel-dot {
  margin-top: 0 !important;
  border-radius: 50px !important;
}

.VueCarousel-dot-container {
  margin: 10px 0 !important;
}
</style>

<style lang="scss" scoped="true">
.ProductCarousel--compact {
  @media only screen and (min-width: $tablet) {
    padding: 0 77px;
  }
}

.CardWrapper {
  margin-right: 20px;
  height: 100%;
}

.LastSlide {
  height: 100%;

  a {
    display: block;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    background-color: #f6f6f6;
    color: #000;
    font-size: 26px;
    transition: all 200ms ease;

    &:hover {
      text-decoration: none;
      color: #666;
      background-color: #eee;
    }
  }
}

.ProductCard {
  height: 100%;
}
</style>
