<template>
  <InstantSearch
    :enable-routing="true"
    :enabled="true"
    :contexts="contexts.split(',')"
    :filters="filters"
    :optional-filters="optionalFilters"
    :analytics-tags="contexts.split(',')"
    :hits-per-page="limit"
  >
    <Hits v-slot="{ hits, trackClick, trackConvension }" hit-key="item_number">
      <div class="ProductsList">
        <h3 v-if="title && hits" class="ProductsList__headline">{{ title }}</h3>
        <div v-if="hits" class="ProductsList__products">
          <ProductCarousel
            :products="hits"
            :last-slide-title="lastSlideTitle"
            :last-slide-link="lastSlideLink"
            :button-location="buttonLocation"
            >{{ hits }}</ProductCarousel
          >
        </div>
      </div>
    </Hits>
  </InstantSearch>
</template>

<script>
import { InstantSearch, Hits, ReferenceHits } from '@components/Search';

import ProductCarousel from '@components/ProductCarousel';
import { routing, searchClient } from '@algolia';
import tracking from '@tracking';
import settings from '@settings';

export default {
  name: 'AlgoliaProductCarousel',

  components: {
    InstantSearch,
    Hits,
    ProductCarousel,
  },

  props: {
    optionalFilters: {
      type: String,
      default: '',
    },
    filters: {
      type: String,
      default: '',
    },
    contexts: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 20,
    },
    lastSlideTitle: {
      type: String,
      default: '',
    },
    lastSlideLink: {
      type: String,
      default: '',
    },
    buttonLocation: {
      type: String,
      default: '',
    },
  },

  data() {
    return {};
  },
  computed: {},

  created() {
    // Listen for this component role event
  },
};
</script>

<style lang="scss" scoped></style>
